/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "Le Portable est le premier roman de Christophe Wojcik. "), "\n", React.createElement("br"), "\n", React.createElement(_components.p, null, "Léo-Paul est un homme à la vie assez ennuyeuse. Lors d’une balade nocturne dans Paris, il tombe sur un homme à l’agonie, Pierre. Avant de mourir, ce dernier lui donne son téléphone. Dans l’appareil, toute la vie du malheureux. Emploi du temps professionnel, mails, messages, photos, réseaux sociaux… \nEst-ce que Pierre ne vivrait pas quelques jours de plus aux yeux du monde ? Pour Léo-Paul, la tentation est grande. Avec ce téléphone, il peut prolonger la vie du pauvre homme tout en sortant de la morosité de sa vie..."), "\n", React.createElement("br"), "\n", React.createElement(_components.p, null, "À mi-chemin entre le polar et la comédie, entre le pervers et le drolatique, cette histoire amène le lecteur à se questionner sur son rapport au smartphone. "), "\n", React.createElement("br"), "\n", React.createElement(_components.p, null, "La narration cadencée est très efficace, le livre est plaisant, accessible et peut se lire d'une traite. Le Portable est pour moi un premier roman original et réussi !"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
